import { useState, useRef, useCallback, useImperativeHandle, forwardRef } from 'react';
import { Grid, makeStyles, Modal } from '@material-ui/core';
import MainButton from '../elements/mainButton';
import colors from '../styles/colors';
import Cookie, { CookiePreferencesRef } from '../elements/cookie';
import { useScrollContext } from '../hooks/useScrollContext';
import { ANALYTICS_COOKIE_NAME } from '../consts/cookies';

export interface ManageCookiesRef {
	openDialog: () => void;
}

const ManageCookies = (_: {}, ref: any) => {
	const [open, setOpen] = useState<boolean>(false);
	const scroll = useScrollContext();
	const classes = useClasses();
	const cookiePreferencesRef = useRef<CookiePreferencesRef | undefined>();

	const openDialog = useCallback(() => {
		if (typeof window !== 'undefined' && scroll !== undefined) {
			if (scroll?.scroll?.windowWidth < 600 || scroll === null) {
				document.querySelector('html').style.overflowY = 'hidden';
			} else {
				scroll.stop();
			}
			setOpen(true);
		}
	}, [scroll]);

	const handleClose = useCallback(
		(event, reason) => {
			if (typeof window !== 'undefined' && scroll !== undefined) {
				if (reason === 'backdropClick') {
					return false;
				}

				if (scroll?.scroll?.windowWidth < 600 || scroll === null) {
					document.querySelector('html').style.overflowY = 'auto';
				} else {
					scroll.start();
				}
				setOpen(false);
			}
		},
		[scroll]
	);

	const save = useCallback(
		(event) => {
			cookiePreferencesRef.current.savePreferences();
			handleClose(event, 'save');
		},
		[cookiePreferencesRef, handleClose]
	);

	useImperativeHandle(
		ref,
		(): ManageCookiesRef => ({
			openDialog,
		})
	);

	return (
		<Modal onClose={handleClose} open={open}>
			<Grid container className={classes.container}>
				<h5 className={classes.title}>Our website uses cookies</h5>
				<Cookie
					compulsory
					switchName="necessary"
					title="Strictly necessary"
					description="These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services."
				/>
				<Cookie
					ref={cookiePreferencesRef}
					switchName={ANALYTICS_COOKIE_NAME}
					title="Analytics cookies"
					description="We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. "
				/>
				<Grid container spacing={4}>
					<Grid item>
						<MainButton text="Save preferences" onClick={save} />
					</Grid>
				</Grid>
			</Grid>
		</Modal>
	);
};

const useClasses = makeStyles({
	title: {
		'@media (max-width: 600px)': {
			margin: '0.4em',
		},
	},
	container: {
		position: 'fixed',
		left: 0,
		bottom: 0,
		backgroundColor: colors.white,
		width: '40vw',
		padding: '10px 5vw 5vh',
		height: '60vh',
		overflowY: 'auto',
		overflowX: 'hidden',
		'@media (max-width: 1170px)': {
			width: '55vw',
		},
		'@media (max-width: 750px)': {
			width: '60vw',
		},
		'@media (max-width: 599px)': {
			width: '100vw',
		},
	},
});

export default forwardRef(ManageCookies);
