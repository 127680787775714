import { makeStyles } from '@material-ui/core';
import { useState, useRef, useCallback, useMemo, useEffect, MouseEvent as ReactMouseEvent } from 'react';
import { Link } from 'gatsby';
import content from '../content/header.json';
import colors from '../styles/colors';
import Rigraf from '../fonts/rigraf/regular/rigraf';
import { smallText } from '../styles/dimensions';
import Navigation from './navigation';
import ArrowUpMenu from '../images/arrowUpMenu';
import CloseIcon from '../images/closeIcon';
import Favicon from '../images/favicon';
import MainLinkButton from '../elements/mainLinkButton';
import { useScrollContext } from '../hooks/useScrollContext';
import { Routes } from '../consts/routes';

interface ClassProps {
	open: boolean;
	loaded: boolean;
}

const Header = () => {
	const [open, setOpen] = useState(false);
	const [loaded, setLoaded] = useState(true);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const timeoutRef = useRef<NodeJS.Timeout>();

	const scroll = useScrollContext();

	const classes = useClasses({ open, loaded });

	const handleLinkClick = useCallback(
		(event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
			if (typeof window !== 'undefined') {
				if (scroll?.scroll?.windowWidth < 600 || scroll === null) {
					document.querySelector('html').style.overflowY = 'auto';
				} else if (scroll) {
					scroll.start();
				}

				// @ts-ignore
				if (window.location.pathname.startsWith(event.target.getAttribute('href')) && scroll) {
					scroll.scrollTo('top');

					setOpen(false);

					if (!loaded) {
						setTimeout(() => {
							setLoaded(true);
						}, 1200);
					}
				}
			}
		},
		[scroll, loaded]
	);

	const handleLogoClick = useCallback(() => {
		if (typeof window !== 'undefined') {
			if (scroll) {
				scroll.start();
			}

			if (window.location.pathname === Routes.home) {
				scroll.scrollTo('top');

				setOpen(false);
				setIsDisabled(true);
				setTimeout(() => {
					setLoaded(true);
					setIsDisabled(false);
				}, 1200);
			}
		}
	}, [scroll]);

	const toggleNavigation = useCallback(() => {
		let timeout: NodeJS.Timeout;
		if (!isDisabled) {
			if (scroll?.scroll?.windowWidth < 600 || scroll === null) {
				open
					? (document.querySelector('html').style.overflowY = 'auto')
					: (document.querySelector('html').style.overflowY = 'hidden');
			} else if (scroll) {
				open ? scroll.start() : scroll.stop();
			}

			clearTimeout(timeoutRef.current);
			setOpen(!open);
			setLoaded(false);

			if (open) {
				timeout = setTimeout(() => {
					setLoaded(true);
				}, 1200);
				timeoutRef.current = timeout;
			}
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [open, scroll, isDisabled]);

	const navigation = useMemo(() => {
		return <Navigation open={open} handleLinkClick={handleLinkClick} />;
	}, [open]);

	return (
		<>
			{navigation}

			<header className={classes.header}>
				<div className={classes.mainDiv}>
					<Link
						to={Routes.home}
						onClick={handleLogoClick}
						aria-label="Go to Home page"
						className={classes.logoContainer}>
						<Favicon />
					</Link>

					<div className={`${classes.menuIcon} ${classes.menuIconArrow}`} onClick={toggleNavigation}>
						<ArrowUpMenu />
					</div>
					<div className={`${classes.menuIcon} ${classes.menuIconClose}`} onClick={toggleNavigation}>
						<CloseIcon />
					</div>
					<div className={`${classes.menuIcon} ${classes.menuIconHamburger}`} onClick={toggleNavigation}>
						<span className={classes.menuSpan}>{content.button.menu}</span>
						<div className={classes.menuOuterDiv}>
							<div className={classes.menuUpperLine}></div>
							<div className={classes.menuLowerLine}></div>
						</div>
					</div>

					<MainLinkButton
						to={Routes.contact}
						onClick={handleLinkClick}
						text={content.button.name}
						className={classes.contactButton}
					/>
				</div>
				<div className={classes.divider}></div>
			</header>
		</>
	);
};

export default Header;

const useClasses = makeStyles({
	header: {
		height: '7.625em',
		borderBottom: '2px solid transparent',
		backgroundColor: colors.transparent,
		position: 'fixed',
		zIndex: 1000,
		top: 0,
		bottom: '7.625em',
		width: '100%',
		transition: 'background-color 0.5s linear',
		transitionDelay: (props: ClassProps) => (props.open ? '0s' : '0.7s'),
		mixBlendMode: 'exclusion',
		'-webkit-tap-highlight-color': 'transparent',
		'-webkit-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		userSelect: 'none',
		'@media (max-width: 599px)': {
			height: '3.75em',
			bottom: '3.75em',
		},
	},
	mainDiv: {
		padding: '0 2.5em',
		margin: '0 auto',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		top: '50%',
		transform: 'translateY(-50%)',
		'@media (max-width: 599px)': {
			padding: '0 0.75em',
		},
	},
	menuIcon: {
		height: '1.375em',
		cursor: 'pointer',
		position: 'absolute',
		left: '50%',
		transform: 'translateX(-50%)',
		padding: '1.25em',
		'@media (max-width: 599px)': {
			left: 'unset',
			right: '1.25em',
			transform: 'translateX(0)',
			padding: '0.5em',
		},
		'& svg': {
			'@media (max-width: 599px)': {
				height: '0.6875em',
			},
		},
	},
	menuIconArrow: (props: ClassProps) => ({
		opacity: props.open ? 1 : 0,
		minHeight: '52px',
		pointerEvents: props.open && !props.loaded ? 'auto' : 'none',
		transition: 'opacity 1.2s linear, fill 1.2s linear',
		'& svg': {
			transform:
				!props.open && !props.loaded ? 'translate(0, 12px) rotate(0deg)' : 'translate(0, 12px) rotateX(180deg)',
			transition: 'transform 0.25s linear',
		},
		'&:hover svg': {
			transform: 'translate(0, 12px) rotateX(0deg)',
		},

		'@media (max-width: 599px)': {
			display: 'none',
		},
	}),
	menuIconClose: (props: ClassProps) => ({
		opacity: props.open ? 1 : 0,
		pointerEvents: props.open && !props.loaded ? 'auto' : 'none',
		transition: 'opacity 1.2s linear, fill 1.2s linear',
		'& svg': {
			height: '1.25em',
			width: '1.25em',
		},
		'@media (min-width: 600px)': {
			display: 'none',
		},
	}),
	menuIconHamburger: (props: ClassProps) => ({
		opacity: props.loaded ? 1 : 0,
		pointerEvents: props.open && !props.loaded ? 'none' : 'auto',
		'@media (max-width: 599px)': {
			transform: 'translateY(10%)',
		},
		'&:hover span': {
			opacity: 1,
		},
		'&:hover div:first-child': {
			top: '9px',
			left: '30.5px',
			transition: 'top 0.2s ease-in, left 0.2s ease-in 0.2s',
			'@media (max-width: 599px)': {
				top: 0,
				left: 0,
			},
		},
		'&:hover div:last-child': {
			top: '7px',
			left: '-30.5px',
			transition: 'top 0.2s ease-in, left 0.2s ease-in 0.2s',
			'@media (max-width: 599px)': {
				top: '8px',
				left: 0,
			},
		},
	}),
	menuOuterDiv: {
		width: '61px',
		height: '20px',
		'@media (max-width: 599px)': {
			width: '24px',
			height: '12px',
		},
	},
	menuUpperLine: {
		width: '61px',
		height: '2px',
		backgroundColor: colors.white,
		position: 'relative',
		top: 0,
		left: 0,
		transition: 'top 0.2s ease-in 0.2s, left 0.2s ease-in',
		'@media (max-width: 599px)': {
			width: '24px',
		},
	},
	menuLowerLine: {
		width: '61px',
		height: '2px',
		backgroundColor: colors.white,
		position: 'relative',
		top: '16px',
		left: 0,
		transition: 'top 0.2s ease-in 0.2s, left 0.2s ease-in',
		'@media (max-width: 599px)': {
			width: '24px',
			top: '8px',
		},
	},
	menuSpan: {
		fontFamily: Rigraf.regular,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		color: colors.white,
		position: 'absolute',
		left: '-70%',
		opacity: 0,
		transition: 'opacity 0.3s ease-in',
		'@media (max-width: 599px)': {
			display: 'none',
		},
	},
	logoContainer: {
		marginRight: 'auto',
		transform: 'translateX(-50%)',
		left: 'calc(7.5em / 2)',
		position: 'absolute',
		userSelect: 'none',
		'@media (max-width: 599px)': {
			left: '1em',
			transform: 'translateX(0%)',
			marginLeft: 0,
			marginRight: 'auto',
		},
		'& svg': {
			marginLeft: 0,
			marginRight: 'auto',
			'@media (max-width: 599px)': {
				width: '2.25em',
				height: '2.25em',
			},
			'@media (min-width: 1200px) and (max-width: 1600px)': {
				transform: 'scale(0.88)',
			},
		},
		'& svg path': {
			fill: colors.white,
			'@media (max-width: 599px)': {
				fill: colors.white,
			},
		},
	},
	divider: {
		height: '2px',
		width: '100%',
		backgroundColor: colors.white,
		position: 'fixed',
		top: '7.625em',
		zIndex: 1001,
		'@media (max-width: 599px)': {
			top: '3.75em',
			backgroundColor: colors.white,
		},
	},
	contactButton: {
		marginLeft: 'auto',
		color: 'white',
		'&:after': {
			border: '2px solid white',
		},
		'@media (max-width: 599px)': {
			display: 'none',
		},
	},
});
