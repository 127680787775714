import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface Props {
	description?: string;
	lang?: string;
	meta?: unknown[];
	title: string;
	metaTitle?: string;
	image?: string;
}

function SEO({ description, lang, meta, title, metaTitle, image }: Props) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
		`
	);

	const metaDescription = description || site.siteMetadata.description;
	const defaultTitle = site.siteMetadata?.title;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={metaTitle ? metaTitle : title}
			titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:image`,
					content: image
						? `${process.env.GATSBY_DOMAIN}${image}`
						: `${process.env.GATSBY_DOMAIN}/og_image.png`,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				// @ts-ignore
			].concat(meta)}
		/>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
};

export default SEO;
