import { makeStyles } from '@material-ui/core';
import { MouseEvent } from 'react';
import Rigraf from '../fonts/rigraf/regular/rigraf';
import colors from '../styles/colors';
import { smallText } from '../styles/dimensions';
// @ts-ignore
import backgroundGradient from '../images/button_gradient.png';

interface Props {
	type?: 'button' | 'submit' | 'reset';
	disabled?: boolean;
	text: string;
	black?: boolean;
	onClick?: (event: MouseEvent<HTMLButtonElement>, reason?: string) => void;
}

const MainButton = (props: Props) => {
	const { type, disabled, text, onClick, black } = props;

	const classes = useClasses({ black });

	return (
		<button type={type} className={classes.button} disabled={disabled} onClick={onClick}>
			<span>{text}</span>
		</button>
	);
};

export default MainButton;

interface StyleProps {
	black: boolean;
}

const useClasses = makeStyles({
	button: (props: StyleProps) => ({
		fontFamily: Rigraf.semiBold,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		padding: '0.625em 2.0625em',
		backgroundImage: props.black ? '' : `url(${backgroundGradient})`,
		backgroundRepeat: props.black ? '' : 'no-repeat',
		backgroundSize: props.black ? '' : 'cover',
		backgroundPosition: props.black ? '' : 'center',
		backgroundClip: props.black ? '' : 'padding-box',
		backgroundColor: props.black ? colors.buttonGray : '',
		color: `${colors.white} !important`,
		marginTop: '3.125em',
		position: 'relative',
		right: '-4px',
		top: '4px',
		transition: 'top 0.2s linear, right 0.2s linear',
		border: 0,
		outline: 0,
		cursor: 'pointer',
		marginBottom: '4px',
		marginRight: '4px',
		userSelect: 'none',
		'& span': {
			fontFamily: Rigraf.semiBold,
			fontSize: smallText.fontSize,
			lineHeight: smallText.lineHeight,
			textAlign: 'center',
			position: 'relative',
			right: '4px',
			top: '-4px',
			transition: 'top 0.2s linear, right 0.2s linear',
			margin: '0 auto',
		},
		'&:after': {
			content: '""',
			position: 'absolute',
			height: 'calc(100% - 4px)',
			width: 'calc(100% - 4px)',
			border: `2px solid ${colors.buttonOutlineBlack}`,
			right: '4px',
			top: '-4px',
			transition: 'top 0.2s linear, right 0.2s linear',
		},
		'&:hover': {
			right: '0px',
			top: '0px',
			'& span': {
				right: '0px',
				top: '0px',
			},
			borderBottom: '4px solid transparent',
			borderRight: '4px solid transparent',
			marginBottom: '0px',
			marginRight: '0px',
		},
		'&:hover:after': {
			right: '0px',
			top: '0px',
		},
	}),
});
