import { createContext, useContext } from 'react';

interface Coordinates {
	x: number;
	y: number;
}

export interface ScrollEvent {
	[key: string]: any;
	delta: Coordinates;
	limit: Coordinates;
	scroll: Coordinates;
	speed: number;
}

interface ScrollOptions {
	offset?: number;
	callback?: Function;
	duration?: number;
	easing?: Array<number>;
	disableLerp?: boolean;
}

export interface Scroll {
	init: () => void;
	on: (eventName: string, callback: Function) => void;
	off: (eventName: string, callback: Function) => void;
	update: () => void;
	destroy: () => void;
	start: () => void;
	stop: () => void;
	scrollTo: (target: Node | 'top' | 'bottom' | number, options?: ScrollOptions) => void;
	scroll: {
		windowWidth: number;
		windowHeight: number;
		windowMiddle: {
			x: number;
			y: number;
		};
		listeners: any;
		[key: string]: any;
	};

	[key: string]: any;
}

export const ScrollContext = createContext(undefined);

export const useScrollContext = (): Scroll => {
	return useContext(ScrollContext);
};

export const scrollInit = () => {
	const LocomotiveScroll = require('locomotive-scroll').default;
	const scroll: Scroll = new LocomotiveScroll({
		el: document.querySelector('[data-scroll-container]'),
		smooth: true,
		scrollFromAnywhere: false,
		smartphone: { smooth: false },
		tablet: { breakpoint: 600, smooth: true },
		firefoxMultiplier: 100,
	});

	if (document.getElementById('lottie-mask')) {
		scroll.stop();
		setTimeout(() => {
			scroll.start();
			scroll.update();
		}, 3000);
	}

	Promise.all(
		Array.from(document.images)
			.filter((img) => !img.complete)
			.map(
				(img) =>
					new Promise((resolve) => {
						img.onload = img.onerror = resolve;
					})
			)
	)
		.then(() => {
			scroll.update();
		})
		.catch(() => {});

	const highlight = document.querySelector('deckgo-highlight-code');
	if (highlight) {
		setTimeout(() => {
			scroll.update();
		}, 2000);
	}

	return scroll;
};
