import { Grid, makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import { useState, useEffect, useCallback, useMemo, MouseEvent as ReactMouseEvent } from 'react';
import content from '../content/navigation.json';
import RigrafExpanded from '../fonts/rigraf/expanded/rigrafExpanded';
import MetroSans from '../fonts/metro-sans/metroSans';
import colors from '../styles/colors';
import { largeText, pageSubtitle, pageTitle, smallSubtitle, smallText } from '../styles/dimensions';
import { Routes } from '../consts/routes';
import Rigraf from '../fonts/rigraf/regular/rigraf';
import MainLinkButton from '../elements/mainLinkButton';
import { StaticImage } from 'gatsby-plugin-image';
import AnimatedImage from '../elements/animatedImage';
import DribbleIcon from '../images/dribbleIcon';
import LinkedInIcon from '../images/linkedInIcon';
import FacebookIcon from '../images/facebookIcon';
// @ts-ignore
import navigationImage from '../images/Menu-photo-2.jpg';

interface ClassProps {
	open: boolean;
	hoveredLink: string;
}

interface Props {
	open: boolean;
	handleLinkClick: (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const Navigation = (props: Props) => {
	const { open, handleLinkClick } = props;

	const [isMobile, setIsMobile] = useState(false);
	const [hoveredLink, setHoveredLink] = useState('');

	const classes = useClasses({ open, hoveredLink });

	useEffect(() => {
		if (typeof window !== 'undefined') {
			if (window.innerWidth < 600) {
				setIsMobile(true);
			}

			const checkSize = () => {
				if (window.innerWidth < 600) {
					setIsMobile(true);
				} else {
					setIsMobile(false);
				}
			};

			window.addEventListener('resize', checkSize, false);
			return () => {
				window.removeEventListener('resize', checkSize, false);
			};
		}
	}, []);

	const handleMouseEnter = useCallback((name: string) => {
		setHoveredLink(name);
	}, []);

	const handleMouseLeave = useCallback(() => {
		setHoveredLink('');
	}, []);

	const socialLinks = useMemo(() => {
		return (
			<>
				<a
					href={Routes.dribble}
					target="_blank"
					rel="noopener"
					role="link"
					aria-label="Link to collective mind DEV Dribble page"
					className={classes.socialLink}>
					<DribbleIcon />
				</a>
				<a
					href={Routes.linkedIn}
					target="_blank"
					rel="noopener"
					role="link"
					aria-label="Link to collective mind DEV LinkedIn page"
					className={classes.socialLink}>
					<LinkedInIcon />
				</a>
				<a
					href={Routes.facebook}
					target="_blank"
					rel="noopener"
					role="link"
					aria-label="Link to collective mind DEV Facebook page"
					className={classes.socialLink}>
					<FacebookIcon />
				</a>
			</>
		);
	}, []);

	return (
		<nav className={classes.navigation}>
			<aside className={classes.aside}>{socialLinks}</aside>

			<Grid container className={classes.grid}>
				<Grid item md={2}></Grid>

				<Grid item xs={12} sm={6} md={4} lg={3} className={classes.dataColumn}>
					<div className={classes.navigationGroup}>
						<p className={classes.breadcrumb} aria-label="Path to selected sub page">
							{content.breadcrumbPrefix + hoveredLink}
						</p>

						<ul className={`${classes.list} ${classes.topNavigationList}`}>
							<li
								className={classes.topNavigationListItem}
								onMouseEnter={() => handleMouseEnter(content.services)}
								onMouseLeave={() => handleMouseLeave()}>
								<Link
									to={Routes.services}
									onClick={handleLinkClick}
									activeClassName={classes.activeLink}
									partiallyActive={true}>
									{content.services}
								</Link>
							</li>
							<li
								className={classes.topNavigationListItem}
								onMouseEnter={() => handleMouseEnter(content.careers)}
								onMouseLeave={() => handleMouseLeave()}>
								<Link
									to={Routes.careers}
									onClick={handleLinkClick}
									activeClassName={classes.activeLink}
									partiallyActive={true}>
									{content.careers}
								</Link>
							</li>
							<li
								className={classes.topNavigationListItem}
								onMouseEnter={() => handleMouseEnter(content.showcase)}
								onMouseLeave={() => handleMouseLeave()}>
								<Link
									to={Routes.showcase}
									onClick={handleLinkClick}
									activeClassName={classes.activeLink}
									partiallyActive={true}>
									{content.showcase}
								</Link>
							</li>
						</ul>

						<ul className={`${classes.list} ${classes.bottomNavigationList}`}>
							<li
								className={classes.bottomNavigationListItem}
								onMouseEnter={() => handleMouseEnter(content.team)}
								onMouseLeave={() => handleMouseLeave()}>
								<Link
									to={Routes.team}
									onClick={handleLinkClick}
									activeClassName={classes.activeLink}
									partiallyActive={true}>
									{content.team}
								</Link>
							</li>
							<li
								className={classes.bottomNavigationListItem}
								onMouseEnter={() => handleMouseEnter(content.blog)}
								onMouseLeave={() => handleMouseLeave()}>
								<Link
									to={Routes.blog}
									onClick={handleLinkClick}
									activeClassName={classes.activeLink}
									partiallyActive={true}>
									{content.blog}
								</Link>
							</li>
						</ul>
						{isMobile && (
							<div className={classes.buttonContainer}>
								<MainLinkButton
									to={content.button.path}
									onClick={handleLinkClick}
									className={classes.button}
									text={content.button.name}
								/>
							</div>
						)}
					</div>

					<div className={classes.mobileSocial}>{socialLinks}</div>
				</Grid>

				<Grid item md={2}></Grid>

				<Grid item sm={6} md={4} lg={5} className={classes.imageColumn}>
					<AnimatedImage image={navigationImage} className={classes.animatedImage} />

					<StaticImage
						src="../images/Menu-photo-2.jpg"
						alt=""
						className={classes.image}
						layout="constrained"
						loading="lazy"
						placeholder="blurred"
						aria-hidden="true"
					/>
				</Grid>
			</Grid>
		</nav>
	);
};

export default Navigation;

const useClasses = makeStyles({
	list: {
		margin: '0',
		'& li a': {
			display: 'inline-flex',
			flexDirection: 'column',
		},
		'& li a:not($activeLink):after': {
			transition: 'transform 0.2s ease-in-out',
			content: '""',
			height: '0px',
			transform: 'scaleX(0)',
		},
		'& li a:not($activeLink):hover:after': {
			height: '4px',
			backgroundColor: colors.textBlack,
			marginTop: '-4px',
			transform: 'scaleX(1)',
			transformOrigin: '0% 50%',
			'@media (max-width: 599px)': {
				backgroundColor: colors.white,
			},
		},
	},
	navigation: {
		position: 'fixed',
		top: 0,
		height: (props: ClassProps) => (props.open ? '100vh' : 0),
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: colors.white,
		zIndex: 1000,
		transition: 'height 0.5s ease-in',
		transitionDelay: (props: ClassProps) => (props.open ? '0s' : '0.7s'),
		display: 'flex',
		flexDirection: 'row',
		'@media (max-width: 599px)': {
			backgroundColor: colors.backgroundBlack,
		},
		'@media (max-width: 599px) and (max-height: 718px)': {
			overflowY: 'auto',
			scrollbarWidth: 'none',
			'&::-webkit-scrollbar-track': {
				'-webkit-box-shadow': 'none !important',
				backgroundColor: 'transparent',
			},
			'&::-webkit-scrollbar': {
				width: '0px !important',
				backgroundColor: 'transparent',
				display: 'none',
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: 'transparent',
			},
		},
	},
	grid: (props: ClassProps) => ({
		opacity: props.open ? 1 : 0,
		pointerEvents: props.open ? 'auto' : 'none',
		transition: 'opacity 0.5s ease-in',
		transitionDelay: props.open ? '0.5s' : '0.2s',
		width: 'calc(100% - 7.5em)',
		marginTop: 'calc(7.625em + 2px)',
		'@media (max-width: 599px)': {
			width: '100%',
			padding: '0 1em',
			marginTop: 'calc(3.75em + 2px)',
		},
	}),
	aside: (props: ClassProps) => ({
		height: props.open ? '100vh' : 'calc(7.625em + 2px)',
		width: '7.5em',
		borderRight: '2px solid black',
		transform: 'translateY(calc(-7.625em - 2px))',
		opacity: props.open ? 1 : 0,
		transition: 'height 0.5s ease-in, opacity 0.5s ease-in',
		transitionDelay: props.open ? '0s' : '0.7s',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		alignItems: 'center',
		paddingBottom: '6em',
		boxSizing: 'border-box',
		position: 'relative',
		zIndex: 0,
		pointerEvents: 'none',
		marginTop: 'calc(7.625em + 2px)',
		'@media (max-width: 599px)': {
			display: 'none',
		},
	}),
	topNavigationListItem: {
		fontFamily: RigrafExpanded.bold,
		...pageTitle,
		color: colors.textBlack,
		margin: '0.8571428571428571em 0',
		'@media (max-width: 599px)': {
			color: colors.white,
			...smallSubtitle,
			margin: '0.75em 0',
		},
		'@media (min-width: 600px) and (max-width: 959px)': {
			...pageSubtitle,
		},
		'@media (min-width: 600px) and (max-height: 780px)': {
			...pageSubtitle,
		},
		'@media (min-width: 600px) and (max-height: 899px)': {
			margin: '0.4285714285714286em 0',
		},
	},
	bottomNavigationListItem: {
		fontFamily: RigrafExpanded.semiBold,
		fontSize: largeText.fontSize,
		lineHeight: largeText.lineHeight,
		color: colors.textBlack,
		margin: '1.1666666666666667em 0',
		'@media (max-width: 599px)': {
			fontFamily: Rigraf.semiBold,
			color: colors.white,
			fontSize: smallText.fontSize,
			lineHeight: smallText.lineHeight,
			margin: '2.5em 0',
		},
	},
	breadcrumb: (props: ClassProps) => ({
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		color: colors.textGrey,
		marginTop: '2em',
		visibility: props.hoveredLink.length > 0 ? 'visible' : 'hidden',
		'@media (max-width: 599px)': {
			display: 'none',
		},
	}),
	socialLink: (props: ClassProps) => ({
		opacity: props.open ? 1 : 0,
		padding: '1.25em',
		pointerEvents: props.open ? 'auto' : 'none',
		transition: 'opacity 0.5s ease-in',
		transitionDelay: props.open ? '0.5s' : '0.2s',
		height: '1.5em',
		width: '1.5em',
		'@media (max-width: 599px)': {
			padding: 0,
			height: '1.25em',
			width: '1.25em',
			'& svg': {
				width: '1.25em',
				height: '1.25em',
			},
			'&>svg>g>g, &>svg>g>path, &>svg>path': {
				fill: colors.white,
			},
		},
	}),
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		objectPosition: 'center top',
		'@media (min-width: 1200px)': {
			display: 'none !important',
		},
	},
	animatedImage: {
		height: '100% !important',
		width: 'auto !important',
		maxWidth: '100%',

		objectFit: 'cover',
		float: 'right',
		transform: 'translateX(5%)',
		'@media (max-width: 1199px)': {
			display: 'none !important',
		},
	},
	imageColumn: (props: ClassProps) => ({
		height: 'calc(100% + 3.75em)',
		transform: 'translateY(-1.75em)',
		opacity: props.open ? 1 : 0,
		transition: 'opacity 0.5s ease-in',
		transitionDelay: props.open ? '0.7s' : '0s',
		'@media (max-width: 1199px)': {
			height: 'calc(100% + 1em)',
			transform: 'translateY(-1em)',
		},
		'@media (max-width: 599px)': {
			display: 'none',
		},
	}),
	dataColumn: {
		paddingBottom: '6em',
		boxSizing: 'border-box',
		'@media (min-width: 1200px)': {
			paddingBottom: '8.75em',
		},
	},
	topNavigationList: {
		'@media (max-width: 599px)': {
			margin: '3.5em 0',
		},
	},
	bottomNavigationList: {
		'@media (max-width: 599px)': {
			marginTop: '3.75em',
			marginBottom: '3.5em',
		},
	},
	mobileSocial: {
		marginTop: '3.5em',
		'@media (min-width: 600px)': {
			display: 'none',
		},
		'&>a': {
			marginRight: '0.75em',
		},
	},
	navigationGroup: {
		'@media (min-width: 600px)': {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			height: '100%',
		},
		'@media (min-width: 600px) and (max-width: 959px)': {
			paddingLeft: '1em',
		},
	},
	buttonContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	button: {
		'&:after': {
			border: `2px solid ${colors.white}`,
		},
		'@media (min-width: 600px)': {
			display: 'none',
		},
	},
	activeLink: {
		color: colors.navigationActiveGray,
		'&:after': {
			content: '""',
			backgroundColor: colors.navigationActiveGray,
			width: '100%',
			height: '4px',
			marginTop: '-4px',
			transform: 'none',
		},
	},
});
