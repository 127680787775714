const colors = {
	backgroundBlack: '#181818',
	headerBlack: '#111111',
	buttonOutlineBlack: '#171717',
	white: '#FFFFFF',
	whiteDimmed: 'rgba(255, 255, 255, 0.8)',
	whiteExtraDimmed: 'rgba(255, 255, 255, 0.4)',
	transparent: '#FFFFFF00',
	textGrey: '#494949',
	textBlack: '#1A1A1A',
	dividerGray: '#979797',
	lightDividerGray: '#D7D7D7',
	lightDivider: '#E4E4E4',
	benefitsBackground: '#1E1E1E',
	careersContactBackground: '#F3F5F7',
	footerSocialFill: '#747474',
	contactInfoColor: '#E0E6EB',
	thngBlue: '#398099',
	thngLightBlue: '#D9E8E2',
	bubblePink: '#F7D4DA',
	errorRed: '#DE1C22',
	lightGray: '#F1F1F1',
	dropdownGray: '#F4F4F4',
	navigationActiveGray: '#B2B2B2',
	careersJobGray: '#D1D1D1',
	lightText: '#A1A1A1',
	buttonGray: '#434343',
	darkGrey: '#656565',
	backgroundDarkGray: '#242424',
	stablerYellow: '#FFD600',
};

export default colors;
