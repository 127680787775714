import { MouseEvent as ReactMouseEvent } from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import Rigraf from '../fonts/rigraf/regular/rigraf';
import colors from '../styles/colors';
import { smallText } from '../styles/dimensions';
// @ts-ignore
import gradient from '../images/button_gradient.png';

interface Props {
	to: string;
	onClick?: (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => void;
	text: string;
	className?: string;
}

const MainLinkButton = (props: Props) => {
	const { to, onClick, text, className } = props;

	const classes = useClasses();

	return (
		<Link to={to} onClick={onClick} className={`${classes.link} ${className}`}>
			<span>{text}</span>
		</Link>
	);
};

export default MainLinkButton;

const useClasses = makeStyles({
	link: {
		padding: '0.625em 2.0625em',
		backgroundImage: `url(${gradient})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundClip: 'padding-box',
		color: '#EEEEEE',
		position: 'relative',
		right: '-4px',
		top: '4px',
		transition: `top 0.2s linear, right 0.2s linear`,
		'& span': {
			fontFamily: Rigraf.semiBold,
			fontSize: smallText.fontSize,
			lineHeight: smallText.lineHeight,
			textAlign: 'center',
			position: 'relative',
			right: '4px',
			top: '-4px',
			transition: 'top 0.2s linear, right 0.2s linear',
			margin: '0 auto',
		},
		'&:after': {
			content: '""',
			position: 'absolute',
			height: 'calc(100% - 4px)',
			width: 'calc(100% - 4px)',
			border: `2px solid ${colors.backgroundBlack}`,
			right: '4px',
			top: '-4px',
			transition: 'top 0.2s linear, right 0.2s linear',
		},
		'&:hover': {
			right: '0px',
			top: '0px',
			'& span': {
				right: '0px',
				top: '0px',
			},
			borderBottom: '4px solid transparent',
			borderRight: '4px solid transparent',
			marginBottom: '-4px',
			marginRight: '-4px',
		},
		'&:hover:after': {
			right: '0px',
			top: '0px',
		},
	},
});
