export enum Routes {
	home = '/',
	services = '/services',
	careers = '/careers',
	showcase = '/showcase',
	team = '/team',
	blog = '/blog',
	contact = '/contact',
	privacyPolicy = '/privacy-policy',

	showcaseQap = '/showcase/qap',
	showcaseThng = '/showcase/thng',
	showcaseVestur = '/showcase/vestur',
	showcaseEnzkreis = '/showcase/enzkreis',
	showcaseTerra = '/showcase/terra',
	showcaseStabler = '/showcase/stabler',

	dribble = 'https://dribbble.com/collectivemind_DEV',
	facebook = 'https://www.facebook.com/collectiveminddev',
	linkedIn = 'https://www.linkedin.com/company/collectiveminddev',
}
