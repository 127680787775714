import {
	Scene,
	PerspectiveCamera,
	WebGLRenderer,
	Clock,
	PlaneGeometry,
	Texture,
	ShaderMaterial,
	Mesh,
	TextureLoader,
} from 'three';
import { Scroll } from '../hooks/useScrollContext';
// @ts-ignore
import vertexShader from '../shaders/vertex.glsl';
// @ts-ignore
import fragmentShader from '../shaders/fragment.glsl';

class Gl {
	private scene: Scene;
	private camera: PerspectiveCamera;
	private renderer: WebGLRenderer;
	private clock: Clock;
	private geometry: PlaneGeometry;
	private texture: Texture;
	private material: ShaderMaterial;
	private mesh: Mesh;
	private canvas: HTMLCanvasElement;
	private image: string;
	private scroll: Scroll | undefined;

	constructor(canvas: HTMLCanvasElement, image: string, scroll: Scroll | undefined) {
		this.canvas = canvas;
		this.image = image;
		this.scroll = scroll;

		this.scene = new Scene();

		this.camera = new PerspectiveCamera(40, window.innerWidth / window.innerHeight, 0.1, 100);

		this.camera.position.z = 1;
		this.renderer = new WebGLRenderer({
			canvas: this.canvas,
			antialias: true,
			alpha: true,
		});
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setSize(window.innerWidth, window.innerHeight);
		this.renderer.setClearColor(0xffffff, 0);

		this.clock = new Clock();
	}

	init() {
		this.createMesh();
		this.addEvents();
	}

	createMesh() {
		this.geometry = new PlaneGeometry((window.innerWidth / window.innerHeight) * 0.7, 0.7, 32, 32);

		this.texture = new TextureLoader().load(this.image, (loadedTexture) => {
			this.renderer.setSize(loadedTexture.image.width, loadedTexture.image.height);
			this.camera.updateProjectionMatrix();

			this.scroll?.update();
		});

		this.material = new ShaderMaterial({
			vertexShader,
			fragmentShader,
			uniforms: {
				uTime: { value: 0.0 },
				uTexture: { value: this.texture },
				uProg: { value: 0.0 },
			},
		});

		this.mesh = new Mesh(this.geometry, this.material);
		this.scene.add(this.mesh);
	}

	addEvents() {
		const requestID = window.requestAnimationFrame(this.run.bind(this));

		let interval: NodeJS.Timeout;

		this.canvas.onmouseenter = () => {
			let count = 0;
			clearInterval(interval);
			interval = setInterval(() => {
				count++;
				if (count >= 10) {
					clearInterval(interval);
					this.material.uniforms.uProg.value = 1;
				} else {
					this.material.uniforms.uProg.value += 0.1;
				}
			}, 20);
		};

		this.canvas.onmouseleave = () => {
			let count = 0;
			clearInterval(interval);
			interval = setInterval(() => {
				count++;
				if (count >= 10) {
					clearInterval(interval);
					this.material.uniforms.uProg.value = 0;
				} else {
					this.material.uniforms.uProg.value -= 0.1;
				}
			}, 20);
		};

		this.canvas.addEventListener(
			'webglcontextlost',
			(event) => {
				event.preventDefault();
				window.cancelAnimationFrame(requestID);
				this.renderer.renderLists.dispose();
				this.renderer.dispose();
				this.material.dispose();
				this.texture.dispose();
				this.geometry.dispose();
			},
			false
		);
	}

	run() {
		requestAnimationFrame(this.run.bind(this));
		this.render();
	}

	render() {
		this.material.uniforms.uTime.value = this.clock.getElapsedTime();
		this.renderer.render(this.scene, this.camera);
	}

	getRenderer() {
		return this.renderer;
	}
}

export default Gl;
