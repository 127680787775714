const FacebookIcon = () => (
	<svg
		width="24px"
		height="24px"
		viewBox="0 0 24 24"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink">
		<g id="UI-Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Menu" transform="translate(-50.000000, -883.000000)" fill="#111111" fillRule="nonzero">
				<g id="facebook_icon" transform="translate(50.000000, 883.000000)">
					<path
						d="M22,0 L2,0 C0.8954305,0 0,0.8954305 0,2 L0,22 C0,23.1045695 0.8954305,24 2,24 L13,24 L13,15 L10,15 L10,11 L13,11 L13,8.413 C13,5.313 14.893,3.625 17.659,3.625 C18.984,3.625 20.122,3.724 20.454,3.768 L20.454,7.008 L18.536,7.009 C17.032,7.009 16.741,7.724 16.741,8.772 L16.741,11 L21.181,11 L20.181,15 L16.741,15 L16.741,24 L22,24 C23.1045695,24 24,23.1045695 24,22 L24,2 C24,0.8954305 23.1045695,0 22,0 Z"
						id="Path"></path>
				</g>
			</g>
		</g>
	</svg>
);

export default FacebookIcon;
