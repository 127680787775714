enum RigrafExpanded {
	bold = 'Rigraf-BoldExpanded',
	regular = 'Rigraf-Expanded',
	extraLight = 'Rigraf-ExtraLightExpanded',
	light = 'Rigraf-LightExpanded',
	medium = 'Rigraf-MediumExpanded',
	semiBold = 'Rigraf-SemiBoldExpanded',
}

export default RigrafExpanded;
