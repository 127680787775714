const DribbleIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 23C5.95 23 1 18.05 1 12C1 5.95 5.95 1 12 1C18.05 1 23 5.95 23 12C23 18.05 18.05 23 12 23ZM21.2583 13.4667C20.8917 13.375 18.325 12.55 15.3917 13.1C16.5833 16.4917 17.1333 19.2417 17.225 19.7917C19.3333 18.4167 20.8917 16.125 21.2583 13.4667ZM15.6667 20.7083C15.4833 19.8833 15.025 17.0417 13.65 13.5583H13.5583C8.24167 15.3917 6.31667 19.0583 6.225 19.425C7.78333 20.7083 9.8 21.4417 12 21.4417C13.2833 21.4417 14.5667 21.1667 15.6667 20.7083ZM5.03333 18.325C5.21667 17.9583 7.78333 13.65 12.6417 12.0917C12.7333 12.0917 12.9167 12 13.0083 12C12.7333 11.45 12.55 10.9 12.275 10.4417C7.6 11.8167 3.01667 11.8167 2.65 11.8167V12.0917C2.65 14.3833 3.475 16.675 5.03333 18.325ZM2.83333 10.075C3.29167 10.075 7.14167 10.075 11.5417 8.975C9.98333 6.225 8.33333 3.84167 8.05833 3.56667C5.4 4.75833 3.38333 7.14167 2.83333 10.075ZM9.8 2.925C10.075 3.29167 11.725 5.58333 13.2833 8.425C16.5833 7.14167 18.05 5.30833 18.2333 5.03333C16.5833 3.475 14.3833 2.65 12 2.65C11.2667 2.65 10.5333 2.74167 9.8 2.925ZM19.2417 6.04167C19.0583 6.31667 17.5 8.33333 14.0167 9.70833C14.2 10.1667 14.475 10.625 14.6583 11.0833C14.75 11.2667 14.75 11.45 14.8417 11.5417C17.9583 11.175 21.075 11.8167 21.35 11.8167C21.35 9.70833 20.6167 7.69167 19.2417 6.04167Z"
			fill="#171717"
		/>
	</svg>
);

export default DribbleIcon;
