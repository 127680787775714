export const defaultSectionWidth = '1200px';

export interface TextDimensions {
	fontSize: string;
	lineHeight: string;
}

export const emphasizedSizeText: TextDimensions = {
	fontSize: '4.5rem',
	lineHeight: '1.5em',
}

export const pageTitle: TextDimensions = {
	fontSize: '3.5rem',
	lineHeight: '1.5em',
};

export const pageSubtitle: TextDimensions = {
	fontSize: '2.5rem',
	lineHeight: '1.5em',
};

export const smallSubtitle: TextDimensions = {
	fontSize: '2rem',
	lineHeight: '1.5em',
};

export const largeText: TextDimensions = {
	fontSize: '1.5rem',
	lineHeight: '1.5em',
};

export const regularText: TextDimensions = {
	fontSize: '1.25rem',
	lineHeight: '1.6em',
};

export const smallText: TextDimensions = {
	fontSize: '1rem',
	lineHeight: '1.5em',
};

export const tinyText: TextDimensions = {
	fontSize: '0.875rem',
	lineHeight: '1.4285714285714286em',
};
export const mobileTinyText: TextDimensions = {
	fontSize: '0.75rem',
	lineHeight: '1.3333333333333333em',
}
