const DropDown = (props: any) => (
	<svg
		width="16px"
		height="16px"
		viewBox="0 0 16 16"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}>
		<g
			id="🖥👈🏽-Privacy-Policy"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			strokeLinecap="round"
			strokeLinejoin="round">
			<g id="Privacy_policy" transform="translate(-1201.000000, -632.000000)" stroke="#111111">
				<g id="Group" transform="translate(1201.000000, 632.000000)">
					<g
						id="drop_down_closed"
						transform="translate(8.000000, 8.000000) scale(1, -1) translate(-8.000000, -8.000000) ">
						<polyline id="Path" points="11.5 9.5 8 6 4.5 9.5"></polyline>
						<circle id="Oval" cx="8" cy="8" r="7.5"></circle>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default DropDown;
