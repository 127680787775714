const CloseIcon = () => (
	<svg
		width="20px"
		height="20px"
		viewBox="0 0 20 20"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink">
		<g id="UI-Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Menu_mobile" transform="translate(-322.000000, -23.000000)" fill="#FFFFFF" fillRule="nonzero">
				<g id="Group" transform="translate(322.000000, 23.000000)">
					<g id="Group-2" transform="translate(0.000000, 0.000000)">
						<polygon
							id="Path"
							transform="translate(10.000000, 10.000000) rotate(-45.000000) translate(-10.000000, -10.000000) "
							points="-2 9 22 9 22 11 -2 11"></polygon>
						<polygon
							id="Path-Copy-2"
							transform="translate(10.000000, 10.000000) scale(-1, 1) rotate(-45.000000) translate(-10.000000, -10.000000) "
							points="-2 9 22 9 22 11 -2 11"></polygon>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default CloseIcon;
