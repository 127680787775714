import { Grid, makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import { useCallback, MouseEvent as ReactMouseEvent } from 'react';
import content from '../content/footer.json';
import colors from '../styles/colors';
import { defaultSectionWidth, smallText, tinyText, regularText } from '../styles/dimensions';
import Rigraf from '../fonts/rigraf/regular/rigraf';
import MetroSans from '../fonts/metro-sans/metroSans';
import { useScrollContext } from '../hooks/useScrollContext';
import { StaticImage } from 'gatsby-plugin-image';
import { Routes } from '../consts/routes';
import LinkedInIcon from '../images/linkedInIcon';
import FacebookIcon from '../images/facebookIcon';
import DribbleIcon from '../images/dribbleIcon';

const Footer = () => {
	const scroll = useScrollContext();

	const classes = useClasses();

	const year = new Date().getFullYear();

	const handleScrollTop = useCallback(
		(event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
			if (typeof window !== 'undefined') {
				event.preventDefault();
				if (scroll === null) {
					window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
				} else {
					scroll.scrollTo('top');
				}
			}
		},
		[scroll]
	);

	const handleLinkClick = useCallback(
		(event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
			if (typeof window !== 'undefined') {
				if (
					// @ts-ignore
					window.location.pathname.startsWith(event.target.getAttribute('href')) &&
					window.location.pathname.split('/').length <= 2
				) {
					if (scroll === null) {
						event.preventDefault();
						window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
					} else if (scroll) {
						scroll.scrollTo('top');
					}
				}
			}
		},
		[scroll]
	);

	const handleHomeClick = useCallback(() => {
		if (typeof window !== 'undefined') {
			if (window.location.pathname === '/') {
				scroll.scrollTo('top');
			}
		}
	}, [scroll]);

	return (
		<footer className={classes.footer} data-scroll>
			<div className={classes.defaultWidth}>
				<Grid container>
					<Grid item sm={1}></Grid>
					<Grid item xs={6} sm={2} className={classes.navigationColumn1}>
						<nav>
							{content.navigationCol1.map((item, key) => (
								<li key={key} className={classes.listItem}>
									<Link to={item.path} onClick={key === 0 ? handleHomeClick : handleLinkClick}>
										{item.name}
									</Link>
								</li>
							))}
						</nav>
					</Grid>

					<Grid item xs={6} sm={2} className={classes.navigationColumn2}>
						<nav>
							{content.navigationCol2.map((item, key) => (
								<li key={key} className={classes.listItem}>
									<Link to={item.path} onClick={handleLinkClick}>
										{item.name}
									</Link>
								</li>
							))}
						</nav>
					</Grid>

					<Grid item sm={3}></Grid>

					<Grid item xs={12} sm={4} className={classes.companyInfo}>
						<address>
							<p>{content.company.name}</p>
							<p>{content.company.address}</p>
							<p>{content.company.city}</p>
							<p>{content.company.country}</p>
						</address>
					</Grid>

					<Grid item sm={1}></Grid>

					<Grid item xs={9} sm={2} className={classes.copyrightColumn}>
						<span className={classes.copyright}>{`${content.copyright} ${year}`}</span>
					</Grid>

					<Grid item xs={3} sm={5} className={classes.scrollUpColumn}>
						<Link to={content.scrollTop.path} onClick={handleScrollTop}>
							<StaticImage
								src="../images/double_arrow_for_up_icon.svg"
								alt="Go on top of the page"
								className={classes.scrollUp}
								layout="constrained"
								loading="lazy"
								placeholder="tracedSVG"
							/>
						</Link>
					</Grid>

					<Grid item xs={12} sm={3} className={classes.privacyColumn}>
						<div>
							<Link
								to={content.privacyPolicy.path}
								className={classes.copyright}
								onClick={handleLinkClick}>
								{content.privacyPolicy.name}
							</Link>
						</div>
						<div className={classes.iconsWrapper}>
							<a
								href={Routes.dribble}
								target="_blank"
								rel="noopener"
								role="link"
								aria-label="Link to collective mind DEV Dribble page"
								className={classes.socialLink}>
								<DribbleIcon />
							</a>
							<a
								href={Routes.facebook}
								target="_blank"
								rel="noopener"
								role="link"
								aria-label="Link to collective mind DEV LinkedIn page"
								className={classes.socialLink}>
								<FacebookIcon />
							</a>
							<a
								href={Routes.linkedIn}
								target="_blank"
								rel="noopener"
								role="link"
								aria-label="Link to collective mind DEV Facebook page"
								className={classes.socialLink}>
								<LinkedInIcon />
							</a>
						</div>
					</Grid>
				</Grid>
			</div>
		</footer>
	);
};

export default Footer;

const useClasses = makeStyles({
	links: {
		'@media (max-width: 599px)': {
			paddingTop: 0 + '!important',
			paddingBottom: '2em !important',
		},
	},
	footer: {
		backgroundColor: colors.backgroundBlack,
		color: colors.white,
		paddingTop: '5.625em',
		paddingBottom: '4em',
		paddingLeft: '1em',
		paddingRight: '1em',
		'@media (max-width: 599px)': {
			paddingTop: '3.25em',
			paddingBottom: '3.25em',
		},
	},
	defaultWidth: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
	},
	companyInfo: {
		'& p': {
			color: colors.whiteDimmed,
			margin: '0.5em 0',
			marginTop: 0,
			'@media (max-width: 599px)': {
				fontSize: tinyText.fontSize,
				lineHeight: tinyText.lineHeight,
				margin: '0.8571428571428571em 0',
			},
		},
	},
	navigationColumn1: {
		'@media (max-width: 599px)': {
			marginBottom: '1.5em',
		},
	},
	navigationColumn2: {
		'@media (max-width: 599px)': {
			marginBottom: '1.5em',
		},
	},
	copyright: {
		fontFamily: MetroSans.regular,
		color: colors.whiteExtraDimmed,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		whiteSpace: 'nowrap',
		'@media (max-width: 599px)': {
			fontSize: tinyText.fontSize,
			lineHeight: tinyText.lineHeight,
			color: colors.lightText,
			display: 'block',
		},
	},
	listItem: {
		fontFamily: Rigraf.semiBold,
		marginBottom: '1.2em',
		fontSize: regularText.fontSize,
		lineHeight: regularText.lineHeight,
		'@media (max-width: 599px)': {
			fontSize: tinyText.fontSize,
			lineHeight: tinyText.lineHeight,
			marginBottom: '2em',
		},
	},
	privacyPolicyLink: {
		fontFamily: Rigraf.semiBold,
		'@media (max-width: 599px)': {
			fontSize: tinyText.fontSize,
			lineHeight: tinyText.lineHeight,
			marginTop: '0.5714285714285714em',
		},
	},
	scrollUp: {
		// @ts-ignore
		position: 'absolute !important',
		left: '50%',
		transform: 'translateX(-50%)',
		'@media (max-width: 599px)': {
			left: 'unset',
			right: '1em',
			transform: 'translateX(0)',
			width: '1em',
			height: '1.25em',
		},
	},
	iconsWrapper: {
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		'@media (max-width: 900px)': {
			marginTop: '1em',
		},
	},
	socialLink: {
		width: '1.25em',
		height: '1.25em',
		flexGrow: 0,
		flexShrink: 0,
		'&:not(:last-child)': {
			'@media (min-width: 600px)': {
				marginLeft: '1.25em',
			},
			'@media (min-width: 900px)': {
				marginLeft: '0.4em',
			},
			'@media (min-width: 1100px)': {
				marginLeft: '1.25em',
			},
		},
		'@media (max-width: 599px)': {
			marginRight: '1.25em',
		},
		'&>svg>g>g, &>svg>g>path, &>svg>path': {
			fill: colors.footerSocialFill,
			'@media (max-width: 599px)': {
				fill: colors.lightText,
			},
		},
		'&>svg': {
			width: '100%',
			height: '100%',
		},
	},
	copyrightColumn: {
		marginTop: '9.5em',
		'@media (max-width: 599px)': {
			marginTop: 0,
			order: 3,
		},
	},
	scrollUpColumn: {
		marginTop: '9.5em',
		'@media (max-width: 599px)': {
			marginTop: 0,
			order: 4,
		},
	},
	privacyColumn: {
		marginTop: '9.5em',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		'@media (max-width: 900px)': {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'@media (max-width: 599px)': {
			marginTop: '2em',
			marginBottom: '1em',
			order: 1,
		},
	},
	socialColumn: {
		marginTop: '9.5em',
		'@media (max-width: 599px)': {
			marginTop: 0,
			marginBottom: '2em',
			order: 2,
		},
	},
});
