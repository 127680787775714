import { useState, useEffect } from 'react';
import Header from './header';
import Footer from './footer';
import CookieConsent from './cookieConsent';
import { Scroll, scrollInit, ScrollContext } from '../hooks/useScrollContext';
import { makeStyles } from '@material-ui/core';
import './layout.css';
import '../fonts/metro-sans/metro-sans-bold/metro-sans-bold.css';
import '../fonts/metro-sans/metro-sans-book/metro-sans-book.css';
import '../fonts/metro-sans/metro-sans-light/metro-sans-light.css';
import '../fonts/metro-sans/metro-sans-regular/metro-sans-regular.css';
import '../fonts/metro-sans/metro-sans-semi-bold/metro-sans-semi-bold.css';
import '../fonts/rigraf/expanded/DXRigraf-Expanded.css';
import '../fonts/rigraf/italic/DXRigraf-Italic.css';
import '../fonts/rigraf/regular/DXRigraf.css';
import { Routes } from '../consts/routes';

interface Props {
	children: any;
	rootClassName?: string;
	[key: string]: any;
}

const Layout = ({ children, rootClassName }: Props) => {
	const [scroll, setScroll] = useState<Scroll | null>();

	const classes = useClasses();

	useEffect(() => {
		if (typeof window !== 'undefined' && typeof document !== 'undefined') {
			if (
				/\/blog\/.+/.test(window.location.pathname) ||
				window.location.pathname.startsWith(Routes.privacyPolicy)
			) {
				const html = document.querySelector('html');

				setScroll(null);

				if (document.getElementById('lottie-mask')) {
					setTimeout(() => {
						html.classList.add('scrollbar-visible');
					}, 3000);
				} else {
					html.classList.add('scrollbar-visible');
				}

				return () => {
					html.classList.remove('scrollbar-visible');
				};
			} else {
				const scroll = scrollInit();
				setScroll(scroll);

				const checkSize = () => {
					scroll.update();
				};
				window.addEventListener('resize', checkSize, false);

				return () => {
					window.removeEventListener('resize', checkSize, false);
					scroll.destroy();
				};
			}
		}
	}, []);

	return (
		<>
			<ScrollContext.Provider value={scroll}>
				<Header />
				<div id="content-root" className={`${classes.mainContainer} ${rootClassName}`} data-scroll-container>
					<main>{children}</main>
					<Footer />
				</div>
				<CookieConsent />
			</ScrollContext.Provider>
		</>
	);
};

const useClasses = makeStyles({
	mainContainer: {
		backgroundColor: 'white',
		position: 'relative',
		zIndex: 1,
	},
});

export default Layout;
