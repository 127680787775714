import { useRef, useEffect } from 'react';
import Gl from './gl';
import { useScrollContext } from '../hooks/useScrollContext';

interface Props {
	image: string;
	className?: string;
}

const AnimatedImage = (props: Props) => {
	const { image, className } = props;

	const scroll = useScrollContext();

	const canvasRef = useRef<HTMLCanvasElement>(null);

	useEffect(() => {
		if (typeof window !== 'undefined' && scroll !== undefined) {

			if (window.innerWidth >= 1200) {
				const scene = new Gl(canvasRef.current, image, scroll);
				scene.init();

				return () => {
					scene.getRenderer().forceContextLoss();
				}
			} else {
				const checkSize = () => {
					if (window.innerWidth >= 1200) {
						const scene = new Gl(canvasRef.current, image, scroll);
						scene.init();
						window.removeEventListener('resize', checkSize, false);

						return () => {
							scene.getRenderer().forceContextLoss();
						};
					}
				};

				window.addEventListener('resize', checkSize, false);

				return () => {
					window.removeEventListener('resize', checkSize, false);
				};
			}
		}
	}, [scroll]);

	return <canvas ref={canvasRef} className={className}></canvas>;
};

export default AnimatedImage;
