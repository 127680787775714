import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	makeStyles,
	Switch,
	Typography,
	withStyles,
} from '@material-ui/core';
import { useLocation } from '@reach/router';
import { useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import { Cookies } from 'react-cookie-consent';
import MetroSans from '../fonts/metro-sans/metroSans';
import { ANALYTICS_COOKIE_NAME, COOKIE_PREFERENCE } from '../consts/cookies';
import DropDown from '../images/privacy/dropDown';
import colors from '../styles/colors';
import { smallText } from '../styles/dimensions';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
// @ts-ignore
import switchGradient from '../images/switch-gradient.png';

export interface CookiePreferencesRef {
	savePreferences: () => void;
}

interface Props {
	title: string;
	description: string;
	switchName: string;
	compulsory?: boolean;
}

const Cookie = (props: Props, ref: any) => {
	const { switchName, title, description, compulsory } = props;
	const classes = useClasses();
	const location = useLocation();
	const [trackingCookie, setTrackingCookie] = useState<boolean>(false);

	const toggleCookie = useCallback(() => {
		if (props.switchName === ANALYTICS_COOKIE_NAME) {
			setTrackingCookie(!trackingCookie);
		}
	}, [trackingCookie, switchName]);

	const savePreferences = useCallback(() => {
		if (trackingCookie) {
			Cookies.set(ANALYTICS_COOKIE_NAME, 'true', { expires: 30, secure: true });
			initializeAndTrack(location);
		} else sessionStorage.setItem(COOKIE_PREFERENCE, COOKIE_PREFERENCE);
	}, [trackingCookie]);

	useImperativeHandle(ref, (): CookiePreferencesRef => ({ savePreferences }));

	return (
		<Grid container spacing={4}>
			<Grid className={classes.center} item md={11} xs={10}>
				<Accordion classes={{ root: classes.accordionRoot }}>
					<AccordionSummary
						classes={{ root: classes.root, content: classes.summaryContent }}
						expandIcon={<DropDown />}>
						<Typography className={classes.heading}>{title}</Typography>
						<Typography className={classes.description}>See description</Typography>
					</AccordionSummary>
					<AccordionDetails classes={{ root: classes.root }}>{description}</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid item md={1} xs={2} className={classes.center}>
				<AntSwitch checked={compulsory} color="default" name={switchName} onClick={toggleCookie} />
			</Grid>
		</Grid>
	);
};

const AntSwitch = withStyles((theme) => ({
	root: {
		width: 28,
		height: 16,
		padding: 0,
		display: 'flex',
		overflow: 'inherit',
	},
	switchBase: {
		padding: 3,
		'&$checked': {
			transform: 'translateX(12px)',
			'& + $track': {
				opacity: 1,
				backgroundImage: `url(${switchGradient})`,
			},
		},
	},
	thumb: {
		width: 12,
		height: 12,
		boxShadow: 'none',
		color: theme.palette.common.white,
	},
	track: {
		border: `1px solid ${theme.palette.grey[500]}`,
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: colors.darkGrey,
	},
	checked: {},
}))(Switch);

const useClasses = makeStyles({
	description: {
		alignSelf: 'center',
	},
	accordionRoot: {
		boxShadow: 'none',
		display: 'initial',
	},
	heading: {
		...smallText,
		fontFamily: MetroSans.bold,
		color: colors.textBlack,
		flexBasis: '50%',
		flexShrink: 0,
		'@media (max-width: 600px)': {
			paddingRight: '0.2em',
		},
	},
	root: {
		padding: 0,
	},
	summaryContent: {
		justifyContent: 'space-between',
	},
	center: {
		alignSelf: 'center',
	},
});

export default forwardRef(Cookie);
