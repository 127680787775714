const ArrowUpMenu = () => (
	<svg
		width="52px"
		height="28px"
		viewBox="0 0 52 28"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink">
		<g id="Mid-fidelity" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
			<g id="Menu-title_hover" transform="translate(-694.000000, -47.000000)" stroke="#fff" strokeWidth="2">
				<g id="arrow_up_menu" transform="translate(696.000000, 49.000000)">
					<polyline id="Path" points="0 24 24 0 48 24"></polyline>
				</g>
			</g>
		</g>
	</svg>
);

export default ArrowUpMenu;
