import { makeStyles } from '@material-ui/core';
import { useState, useRef, useEffect, useCallback } from 'react';
import Consent from 'react-cookie-consent';
import MetroSans from '../fonts/metro-sans/metroSans';
import colors from '../styles/colors';
import { smallText } from '../styles/dimensions';
import { Link } from 'gatsby';
import Rigraf from '../fonts/rigraf/regular/rigraf';
import ManageCookies, { ManageCookiesRef } from './manageCookies';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { COOKIE_PREFERENCE, ANALYTICS_COOKIE_NAME } from '../consts/cookies';
import Cookies from 'js-cookie';
import content from '../content/cookieConsent.json';
// @ts-ignore
import buttonGradient from '../images/button_gradient.png';
import { Routes } from '../consts/routes';

const CookieConsent = () => {
	const [showConsent, setShowConsent] = useState<boolean>(true);
	const manageCookiesRef = useRef<ManageCookiesRef | undefined>();
	const location = useLocation();
	const classes = useClasses();
	const consentRef = useRef(null);

	useEffect(() => {
		if (sessionStorage.getItem(COOKIE_PREFERENCE) || Cookies.get(ANALYTICS_COOKIE_NAME) === 'true') {
			setShowConsent(false);
		}
	}, []);

	const handlePrivacyClick = useCallback((event) => {
		if (typeof window !== 'undefined' && location.pathname.startsWith('/privacy-policy')) {
			event.preventDefault();
			window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		}
	}, []);

	const openManageCookies = useCallback(() => {
		manageCookiesRef.current.openDialog();
		setShowConsent(false);
	}, [manageCookiesRef]);

	const onAccept = useCallback(() => {
		Cookies.set(ANALYTICS_COOKIE_NAME, 'true', { expires: 30 });
		initializeAndTrack(location);
	}, [location]);

	return (
		<>
			{showConsent ? (
				<Consent
					// @ts-ignore
					hideOnDecline={false}
					location="bottom"
					contentClasses={classes.content}
					containerClasses={classes.container}
					buttonWrapperClasses={classes.buttonWrapper}
					buttonClasses={classes.button}
					buttonText={content.buttonText}
					enableDeclineButton
					declineButtonClasses={classes.darkButton}
					declineButtonText={content.declineButtonText}
					onDecline={openManageCookies}
					setDeclineCookie={false}
					onAccept={onAccept}
					cookieSecurity={true}
					cookieName="gatsby-gdpr-google-analytics"
					ref={consentRef}>
					<span className={classes.contentSpan}>
						{content.description}
						<Link
							className={classes.privacyPolicy}
							to={Routes.privacyPolicy}
							onClick={handlePrivacyClick}>
							{content.link}
						</Link>
						
					</span>
				</Consent>
			) : null}
			<ManageCookies ref={manageCookiesRef} />
		</>
	);
};

export default CookieConsent;

export const buttonBase = {
	fontFamily: Rigraf.semiBold,
	fontSize: smallText.fontSize,
	lineHeight: smallText.lineHeight,
	'&:after': {
		content: '""',
		position: 'absolute',
		height: 'calc(100% - 4px)',
		width: 'calc(100% - 4px)',
		border: `2px solid ${colors.buttonOutlineBlack}`,
		right: '4px',
		top: '-4px',
		transition: 'top 0.2s linear, right 0.2s linear',
	},
	'&:hover': {
		right: '0px',
		top: '0px',
		'& span': {
			right: '0px',
			top: '0px',
		},
		borderBottom: '4px solid transparent',
		borderRight: '4px solid transparent',
		marginBottom: '0px',
		marginRight: '0px',
	},
	'&:hover:after': {
		right: '0px',
		top: '0px',
	},
	color: `${colors.white} !important`,

	right: '-4px',
	top: '4px',
	transition: 'top 0.2s linear, right 0.2s linear',
	border: 0,
	outline: 0,
	cursor: 'pointer',
};

const useClasses = makeStyles({
	buttonWrapper: {},
	content: {
		fontFamily: MetroSans.book,
		margin: '0 !important',
		'@media (min-width: 645px)': {
			paddingRight: '2em',
		},
		'@media (max-width: 364px)': {
			flex: 'unset !important',
		},
	},
	contentSpan: {
		fontFamily: MetroSans.book,
		color: colors.textGrey,
		...smallText,
	},
	container: {
		background: colors.white + ' !important',
		alignItems: 'center !important',
		boxSizing: 'border-box',
		padding: '1em 2.5em',
		'@media (min-width: 600px)': {},
		'@media (max-width: 951px)': {
			alignItems: 'flex-start !important',
		},
		'@media (max-width: 364px)': {
			display: 'flex',
			flexWrap: 'initial',
		},
	},
	button: {
		margin: '0.25em 0 !important',
		position: 'relative',
		...buttonBase,
		backgroundImage: `url(${buttonGradient}) !important`,
		backgroundRepeat: 'no-repeat !important',
		backgroundSize: 'cover !important',
		backgroundPosition: 'center !important',
		backgroundClip: 'padding-box !important',
		'@media (max-width: 644px)': {
			margin: '1em 0 !important',
		},
		'@media (min-width: 401px)': {
			padding: '0.625em 2em !important',
		},
	},
	darkButton: {
		margin: '0 1em 0 0 !important',
		...buttonBase,
		position: 'relative',
		backgroundColor: colors.buttonGray + '!important',
		whiteSpace: 'nowrap',
		'@media (min-width: 401px)': {
			padding: '0.625em 2em !important',
		},
	},
	privacyPolicy: {
		textDecoration: 'underline',
		whiteSpace: 'nowrap',
	},
});
