enum MetroSans {
    bold = 'MetroSans-Bold',
    book = 'MetroSans-Book',
    light = 'MetroSans-Light',
    regular = 'MetroSans-Regular',
    regularItalic = 'MetroSans-RegularItalic',
    semiBold = 'MetroSans-Semi-Bold',
}

export default MetroSans;
